import { useState, useEffect } from 'react';
import './stylesheets/Products.css';

import loading from '../../media/images/loading.gif';

import ProductRow from './components/ProductRow.js';

const Products = () => {

    const [searchKey, setSearchKey] = useState("");
    const [products, setProducts] = useState([]);
    const [isProductsLoaded, setIsProductsLoaded] = useState(false);

    const handleSearchChange = (event) => {
        setSearchKey(event.target.value.toLowerCase());
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://gantrymatic.com/DBConnection/getProducts.php');
                if (!response.ok) {
                    throw new Error('Something went wrong fetching product data.');
                }
                const data = await response.json();
                setProducts(data);
                setIsProductsLoaded(true);
            } catch (error) {
                console.error('Error while fetching product data.', error);
            }
        }
        fetchData();
    }, []);

    // Function to format products by category
    function formatProductsByCategory(products) {

        const formattedProducts = [];

        // Helper object to keep track of categories
        const categories = {};

        products.forEach(product => {

            const category = product.product_category;

            // If the category does not exist in the categories object, add it
            if (!categories[category]) {
                categories[category] = {
                    category: category,
                    products: []
                };
                formattedProducts.push(categories[category]);
            }

            // Add the product to the appropriate category
            categories[category].products.push(product);
        });

        return formattedProducts;
    }

    const formattedProducts = formatProductsByCategory(products);

    const filteredProducts = formattedProducts.map(category => ({
        ...category,
        products: category.products.filter(product =>
          product.product_name.toLowerCase().includes(searchKey) ||
          product.description.toLowerCase().includes(searchKey)
        )
    })).filter(category => category.products.length > 0);

    return (
        <div>
            {/* Loading */}
            {
                !isProductsLoaded &&
                <div className='products-center'>
                    <div className='loading-gif'>
                        <img src={loading} alt="loading..." />
                        <div>Loading Products...</div>
                    </div>
                </div>
            }
            {/* Loaded */}
            {
                isProductsLoaded && 
                <div>
                    {/* Search */}
                    <input 
                        type='text' 
                        value={searchKey}
                        onChange={handleSearchChange}
                        placeholder='Search products...'
                        className='product-search'
                    ></input>
                    { filteredProducts.length > 0 && 
                        <div>
                            {/* Types */}
                            <ProductRow categories={filteredProducts}/>
                        </div>
                        
                    }
                    { filteredProducts.length === 0 &&
                        <div className='products-center'>
                            <div className='no-products-text'>There are no products to display, please try again.</div>
                        </div>
                    }
                </div>
            }
            
        </div>
    );
};

export default Products;