import React from "react";
import ResetPassword from "./Components/ResetPassword";
import './Stylesheets/UserDashboard.css';

const UserDashboard = ({ userData, handleLogout }) => {
    return (
        <div className="dashboard-container">
            <h1 className="welcome-message">
                Welcome, {userData.username}!
            </h1>
            <hr className="hr-dashboard"/>
            <div className="dashboard-body">
                <div className="dashboard-section left-content">
                    <h2>User Information</h2>
                    <div className="user-info"><div className="info-title">Email:</div> {userData.email}</div>
                    <div className="user-info"><div className="info-title">Username:</div> {userData.username}</div>
                    <div className="user-info"><div className="info-title">Registration Date:</div> {userData.created_at}</div>
                    <button className="logout-button active-button" onClick={handleLogout}>Logout</button>
                </div>
                <div className="dashboard-section right-content">
                    <h2>Reset Password</h2>
                    <ResetPassword userData={userData} />
                </div>
            </div>
        </div>
    );
}

export default UserDashboard;
