import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Stylesheets/SignUp.css';
import axios from 'axios';

const SignUp = ({ openLogin }) => {

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post('https://gantrymatic.com/DBConnection/userRegistration.php', {
        username: username,
        password: password,
        email: email
      });

      const data = response.data;

      if (data.message === 'User registered successfully') {
        alert('User registered successfully. Please log in.');
        openLogin();
      } else {
        alert('Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('There was an error.', error);
    }
  };

  const handleLogin = () => {
    openLogin();
  }

  return (
    <div className="signup-container">
      <div className='signup-title'>Sign Up</div>
      <p className='subtitle'>Please fill this form to create an account.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor='email' className='signup-subtitle'>Email</label>
          <input
            type="text"
            id="email"
            name="email"
            value={email}
            placeholder='Enter email...'
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor='username' className='signup-subtitle'>Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            placeholder='Enter username...'
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor='password' className='signup-subtitle'>Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            placeholder='Enter password...'
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor='confirm-password' className='signup-subtitle'>Confirm Password</label>
          <input
            type="password"
            id="confirm-password"
            name="confirm-password"
            value={confirmPassword}
            placeholder='Confirm password...'
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="active-button">Sign Up</button>
      </form>
      <div>
        <p>Already have an account?</p>
        <Link to="/login" className='active-button link' onClick={handleLogin}>Log in now!</Link>
      </div>
    </div>
  );
}

export default SignUp;
