import Carousel from './components/Carousel.js';

const Home = () => {

  return (
    <div>
        <Carousel />
        <div className='home-body'>
          <h2>Hello, World!</h2>
          <hr></hr>
          <p>Our website is currently under construction to provide a streamlined and innovative online experience. We're diligently working to create a platform that will showcase our latest machines and products, designed to elevate your experience and efficiency. Stay tuned for the unveiling of exciting features and offerings. Thank you for your patience as we prepare to launch something genuinely exceptional!</p>
        </div>
        <br />
        <br />    
        <br />
    </div>
  );
};

export default Home;