import React from "react";
import '../stylesheets/GetQuotePopup.css';

const GetQuotePopup = ({ onClose }) => {
    return (
        <div className="popup-container">
            <div className="popup">
                <button className="close-button" onClick={onClose}>&times;</button>
                <div className="popup-content">
                    <h3>Contact Us</h3>
                    <hr />
                    <p>Email: <a href="mailto:sales@gantrymatic.com">sales@gantrymatic.com</a></p>
                </div>
            </div>
        </div>
    );
}

export default GetQuotePopup;
