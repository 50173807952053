import React, { useState, useEffect } from 'react';
import '../stylesheets/ProductTab.css';

const ProductTab = ({ product }) => {
  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    let interval;
    if (hovering) {
      // Will change the image every 1.5 seconds
      interval = setInterval(() => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % product.image_paths.length);
      }, 1500);
    } else {
      // Will resets it back to first image when not hovering
      setCurrentImageIndex(0);
    }
    return () => clearInterval(interval);
  }, [hovering, product.image_paths.length]);

  return (
    <div
      className={`product-tab-container ${hovering ? 'hover' : ''}`}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <div className="product-tab-image-container">
        <img src={JSON.parse(product.image_paths)[currentImageIndex]} className="product-tab-image" alt={product.product_name} />
      </div>
      <div className="product-tab-info">
        <h2>{product.product_name}</h2>
        <p>{product.description}</p>
        <p>${product.price}</p>
      </div>
    </div>
  );
};

export default ProductTab;