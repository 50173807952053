import React, { useState, useEffect } from 'react';
import '../stylesheets/Carousel.css'

import img1 from '../../../media/images/image1-url.jpg';
import img2 from '../../../media/images/image2-url.jpg';
import img3 from '../../../media/images/image3-url.jpg';

const Carousel = () => {

  const [slideIndex, setSlideIndex] = useState(0);
  const slides = [
    { src: img1, alt: 'Image 1' },
    { src: img2, alt: 'Image 2' },
    { src: img3, alt: 'Image 3' }
  ];

  useEffect(() => {
    const showSlides = () => {
      setSlideIndex(prevIndex => (prevIndex + 1) % slides.length);
    };
    const interval = setInterval(showSlides, 4000);
    return () => clearInterval(interval);
  }, [slides.length]);

  const nextSlide = () => {
    setSlideIndex(prevIndex => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setSlideIndex(prevIndex => (prevIndex - 1 + slides.length) % slides.length);
  };

  return (
    <div className="carousel-container">
      {slides.map((slide, index) => (
        <div key={index} className={`carousel-slide ${index === slideIndex ? 'active' : ''}`}>
          <img src={slide.src} alt={slide.alt} />
        </div>
      ))}
      <a className="carousel-prev" onClick={() => prevSlide()}>&#10094;</a>
      <a className="carousel-next" onClick={() => nextSlide()}>&#10095;</a>
    </div>
  );
};

export default Carousel;