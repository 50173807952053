import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import '../stylesheets/ProductDescriptionPage.css';

import ProductImageGallery from './ProductImageGallery';
import GetQuotePopup from './GetQuotePopup';

import loading from '../../../media/images/loading.gif';

const ProductDescriptionPage = () => {

    const { product_name } = useParams();
    const navigate = useNavigate();

    const [showPopup, setShowPopup] = useState(false);
    const [productData, setProductData] = useState({
        product_id: null,
        product_name: null,
        description: null,
        price: null,
        image_paths: null,
        product_category:  null
    });
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://gantrymatic.com/DBConnection/getProductByID.php?product_name=${product_name}`);
                if (!response.ok) {
                    throw new Error('Something went wrong fetching product data.');
                }
                const data = await response.json();

                // No product data returned
                if (!data || Object.keys(data).length === 0) {
                    navigate("/product-not-found");
                } else {
                    setProductData(data);
                    document.title = `Gantrymatic | ${data.product_name}`;
                    setIsLoaded(true);
                }
            } catch (error) {
                console.error('Error while fetching product data.', error);
                // Issues with finding product / getting data back from server
                navigate("/product-not-found");
            }
        };
        fetchData();
    }, [navigate, product_name]);

    // Function to safely parse JSON
    const safeParseJSON = (jsonString) => {
        if (jsonString === null) {
            return [];
        } 
        try {
            return JSON.parse(jsonString);
        } catch (error) {
            console.error('Invalid JSON string:', error);
            return [];
        }
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    return (
        <div>
            <div>
                {/* Loading */}
                {
                    !isLoaded &&
                    <div className='products-center'>
                        <div className='loading-gif'>
                            <img src={loading} alt="loading..." />
                            <div>Loading Product...</div>
                        </div>
                    </div>
                }
            </div>

            <div className="product-description-page-container">
                {/* Loaded */}
                {
                    isLoaded && 
                    <div>
                        <div className='image-gallery'>
                            <ProductImageGallery imageUrls={safeParseJSON(productData.image_paths)}/>
                        </div>
                        <div className='product-information'>
                            <h2>{ productData.product_name }</h2>
                            <p>{ productData.description }</p>
                            <p>${ productData.price }</p>
                            <button
                                className='get-quote-button active-button'
                                onClick={togglePopup}
                            >Get Quote</button>
                        </div>
                        {showPopup && <GetQuotePopup onClose={togglePopup} />}
                    </div>
                }
                
            </div>
        </div>
    );

}

export default ProductDescriptionPage;