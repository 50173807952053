import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import ProductTab from "./ProductTab";

import '../stylesheets/ProductRow.css';

const ProductRow = ({ categories }) => {

    const scrollContainerRefs = useRef({});

    const navigate = useNavigate();

    const scroll = (category, direction) => {
        const scrollContainerRef = scrollContainerRefs.current[category];
        if (!scrollContainerRef) return;

        const scrollAmount = 210;
        if (direction === 'left') {
            scrollContainerRef.scrollLeft -= scrollAmount;
        } else {
            scrollContainerRef.scrollLeft += scrollAmount;
        }
    };

    return (
        <div>
            {categories.map((categoryData, index) => (
                <div key={index}>
                    <h2 className="row-title">{categoryData.category}</h2>
                    <hr/>
                    <div className="scroll-container">
                        <button className="scroll-button left" onClick={() => scroll(categoryData.category, 'left')}>&#10094;</button>
                        <div className="product-row" ref={el => (scrollContainerRefs.current[categoryData.category] = el)}>
                            {categoryData.products.map((product, productIndex) => (
                                <div key={productIndex} onClick={() => navigate(`/products/${encodeURIComponent(product.product_name)}`)}><ProductTab key={productIndex} product={product}/></div>
                            ))}
                        </div>
                        <button className="scroll-button right" onClick={() => scroll(categoryData.category, 'right')}>&#10095;</button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProductRow;
