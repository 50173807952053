// Import all libraries
import { useReducer, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { CookiesProvider, useCookies } from 'react-cookie';
import { BrowserRouter as Router, Routes, Route, useNavigate, Link } from 'react-router-dom';

// Import all components
import Home from './pages/Home/Home.js';
import Products from './pages/Products/Products.js';
import Contact from './pages/Contact/Contact.js';
import Login from './pages/Accounts/Login/Login.js';
import SignUp from './pages/Accounts/SignUp/SignUp.js';
import ProductDescriptionPage from './pages/Products/components/ProductDescriptionPage.js';
import UserDashboard from './pages/Accounts/UserDashboard/UserDashboard.js';
import ProductNotFound from './pages/404/ProductNotFound.js';
import _404 from './pages/404/_404.js';

// Import all required images
import logo from './media/images/Gantrymatic_Logo_WHT_transparent_bg.png';
import search from './media/images/search-icon.png';
import userIcon from './media/images/user-icon.png';

// Import all styling
import './App.css';

const initialState = {
  pageTitle: "Gantrymatic | Homepage",
  isLoggedIn: false,
  user: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PAGE':
      return { ...state, pageTitle: action.pageTitle };
    case 'SET_LOGIN':
      return { ...state, isLoggedIn: true, user: action.user };
    case 'SET_LOGOUT':
      return { ...state, isLoggedIn: false, user: null };
    case 'SET_DASHBOARD':
      return { ...state, pageTitle: `Gantrymatic | ${state.user.username}` };
    case 'SET_HOME':
      return { ...state, pageTitle: "Gantrymatic | Home" };
    default:
      return state;
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  // Used for page navigation
  const navigate = useNavigate();

  useEffect(() => {
    document.title = state.pageTitle;
  }, [state.pageTitle]);

  useEffect(() => {
    const sessionUser = cookies.user;
    if (sessionUser) {
      axios.post('https://gantrymatic.com/DBConnection/sessionValidation.php', {
        sessionId: sessionUser
      }).then(response => {
        if (response.data.valid) {
          dispatch({ type: 'SET_LOGIN', user: response.data.userData });
          setCookie('user', response.data.userData, { path: '/' });
        }
      }).catch(error => {
        console.error('There was an error validating the session.', error);
      });
    }
  }, [cookies, setCookie]);

  const setPage = (pageState, pageTitle) => {
    dispatch({ type: 'SET_PAGE', pageState, pageTitle });
  };

  const handleSuccessfulLogin = (user) => {
    setCookie('user', user, { path: '/' });
    dispatch({ type: 'SET_LOGIN', user });
    dispatch({ type: 'SET_HOME', user });
  };

  const handleLogout = () => {
    removeCookie('user', { path: '/' });
    dispatch({ type: 'SET_LOGOUT' });
    setPage(3, "Gantrymatic | Login");
  };

  const toggleMenu = () => {
    const mobileMenu = $('#mobile-menu');
    const menuButton = $('.mobile-menu-button');

    mobileMenu.toggleClass('open');

    if (mobileMenu.hasClass('open')) {
      mobileMenu.slideDown();
    } else {
      mobileMenu.slideUp();
    }

    menuButton.toggleClass('spin');
  };

  return (
    <div className="App">

      {/* Navbar */}
      <div className="navbar-container">
        <div className="navbar-left">
          <img src={logo} className="navbar-logo" alt="logo" onClick={
            () => { 
              setPage("Gantrymatic | Homepage"); 
              navigate("/");
            }} 
          />
          <div className="desktop-menu">
            <button className='nav-button active-button' onClick={() => {
              setPage("Gantrymatic | Products");
              navigate("/products");
            }}>Products</button>
            <button className='nav-button active-button' onClick={() => {
              setPage("Gantrymatic | Contact");
              navigate("/contact");
            }}>Contact</button>
            <button className='nav-button active-button'>
              <img className="search-icon" src={search} alt="search" />
            </button>
          </div>
        </div>

        <div className="desktop-login">
          <CookiesProvider>
            {state.isLoggedIn ? (
              <img src={userIcon} alt='User Icon' onClick={() => {
                dispatch({ type: 'SET_DASHBOARD' });
                navigate("/user-dashboard");
              }} 
              className='user-icon active-button login-button' 
              />
            ) : (
              <button className='nav-button login-button active-button' onClick={() => {
                setPage(3, "Gantrymatic | Login");
                navigate("/login");
              }}>Login</button>
            )}
          </CookiesProvider>
        </div>

        <button className="mobile-menu-button active-button" onClick={toggleMenu}>
          ☰
        </button>
      </div>

      <div className="mobile-menu" id="mobile-menu">
        <button className='nav-button active-button' onClick={() => {
          setPage("Gantrymatic | Products");
          navigate("/products");
        }}>Products</button>
        <button className='nav-button active-button' onClick={() => {
          setPage("Gantrymatic | Contact");
          navigate("/contact");
        }}>Contact</button>
        <button className='nav-button'>
          <img className="search-icon" src={search} alt="search" />
        </button>
      </div>

      {/* Page content */}
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={
            <Products />
          } />
          <Route path="/products/:product_name" element={
            <ProductDescriptionPage /> 
          }/>
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={
            <Login openSignUp={() => setPage(4, "Gantrymatic | Register")} onSuccessfulLogin={handleSuccessfulLogin} />
          } />
          <Route path="/signup" element={
            <SignUp openLogin={() => setPage(3, "Gantrymatic | Login")} />
          } />
          <Route path="/user-dashboard" element={
            <UserDashboard userData={state.user} handleLogout={handleLogout} />
          } />
          <Route path="/product-not-found" element={
            <ProductNotFound />
          }/>
          <Route path="*" element={
            <_404 />
          }/>
      </Routes>

      





      {/* Footer */}
      <div className="footer">
        <div className="footer-left">
          <p>© 2024 Gantrymatic. All rights reserved.</p>
        </div>
        <div className="footer-right">
          <p>
            <a href="https://gantrymatic.com/index.php">Privacy Policy</a> | 
            <a href="https://gantrymatic.com/index.php"> Terms of Use</a> | 
            <a><Link to="/contact"> Contact Us</Link></a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;