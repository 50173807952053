import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import './Stylesheets/Login.css';

function Login({ openSignUp, onSuccessfulLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    try {
      const response = await axios.post('https://gantrymatic.com/DBConnection/userLogin.php', {
        username: username,
        password: password
      });

      const data = response.data;

      if (data.message === "Invalid username or password") {
        setErrorMessage('Invalid username or password.');
      } else if (data.message === "User logged in successfully") {
        const user = {
          "username": data.username,
          "email": data.email,
          "created_at": data.created_at
        };
        alert('User logged in successfully!');
        onSuccessfulLogin(user);
      } else {
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      console.error('There was an error.', error);
      setErrorMessage('There was an error connecting to the server.');
    }
  };

  return (
    <div className="login-container">
      <div className='login-title'>Login</div>
      <p className='subtitle'>Please fill in your credentials to log in.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor='username' className='login-subtitle'>Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            placeholder='Enter username...'
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor='password' className='login-subtitle'>Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            placeholder='Enter password...'
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="active-button">Log In</button>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
      <div className='sign-up'>
        <p>Don't have an account?</p>
        <Link to="/signup" className='active-button link' onClick={openSignUp}>Sign up now.</Link>
      </div>
    </div>
  );
}

export default Login;
