import React from "react";
import { useNavigate } from "react-router";

import './Stylesheets/NotFoundStyles.css';

const _404 = () => {

    const navigate = useNavigate();

    return (
        <div className="not-found-container">
        <h1>Page Not Found</h1>
        <p>The page you are looking for might have been moved, removed, or does not exist.</p>
        
        <p>If you believe this is an error, please let us know by <a href="mailto:sales@gantrymatic.com">contacting us</a>.</p>
        <p>Thank you!</p>
        <p>The Gantrymatic Team</p>
        
        <div className="button-container">
            <button 
                onClick={() => navigate('/')}
                className="home-button"
            >Back to Homepage</button>
        </div>

        <p>© 2024 Gantrymatic</p>
    </div>
    );
};

export default _404;