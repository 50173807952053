import React, { useState } from "react";
import axios from "axios";
import '../Stylesheets/ResetPassword.css';

const ResetPassword = ({ userData }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmNewPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await axios.post('https://gantrymatic.com/DBConnection/resetPassword.php', {
                username: userData.username,
                currentPassword: currentPassword,
                newPassword: newPassword
            });

            const data = response.data;

            if (data.message) {
                setMessage(data.message);
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
            } else if (data.error) {
                setError(data.error);
            }
        } catch (error) {
            console.error('There was an error.', error);
            setError('Failed to reset password.');
        }
    };

    return (
        <div className="reset-container">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor='currentPassword' className='reset-subtitle'>Current Password</label>
                    <input
                        type="password"
                        id="currentPassword"
                        name="currentPassword"
                        value={currentPassword}
                        placeholder='Enter current password...'
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor='newPassword' className='signup-subtitle'>New Password</label>
                    <input
                        type="password"
                        id="newPassword"
                        name="newPassword"
                        value={newPassword}
                        placeholder='Enter new password...'
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor='confirmNewPassword' className='signup-subtitle'>Confirm New Password</label>
                    <input
                        type="password"
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        value={confirmNewPassword}
                        placeholder='Confirm new password...'
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="active-button">Save Changes</button>
            </form>
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default ResetPassword;
