import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../stylesheets/ProductImageGallery.css';

const ProductImageGallery = ({ imageUrls }) => {
  
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (imageUrls.length > 0 && selectedImage === null) {
      setSelectedImage(imageUrls[0]);
    }
  }, [imageUrls]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  return (
    <div className="product-image-gallery">
      <div className="main-image-container">
        <img src={selectedImage} alt="Selected Product" className="main-image" />
      </div>
      <div className="thumbnail-container">
        {imageUrls.map((url, index) => (
          <div
            key={index}
            className={`thumbnail-wrapper ${url === selectedImage ? 'selected' : ''}`}
            onClick={() => handleImageClick(url)}
          >
            <img
              src={url}
              alt={`Product Thumbnail ${index + 1}`}
              className="thumbnail"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

ProductImageGallery.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProductImageGallery;