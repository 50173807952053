import React from "react";
import { useNavigate } from 'react-router-dom';

import './Stylesheets/NotFoundStyles.css';

const ProductNotFound = () => {

    const navigate = useNavigate();

    return (
        <div className="not-found-container">
            <h1>Product Not Found</h1>
            <p>Sorry, the product you're looking for does not exist, or we are having troubles loading it for you.</p>
            <p>If you believe this is an error, please let us know by <a href="mailto:sales@gantrymatic.com">contacting us</a>.</p>
            <p>Thank you!</p>
            <p>The Gantrymatic Team</p>
            
            <div className="button-container">
                <button 
                    onClick={() => navigate('/')}
                    className="home-button"
                >Back to Homepage</button>
                <button 
                    onClick={() => navigate('/products')}
                    className="products-button"
                >Back to Products</button>
            </div>

            <p>© 2024 Gantrymatic</p>
        </div>
    );
};

export default ProductNotFound;