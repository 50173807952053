import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import './Stylesheets/Contact.css';

const Contact = () => {

    const containerStyle = {
        width: '80%',
        height: '400px'
    };
      
    const center = {
        lat: 41.151080,
        lng: -73.240750
    };

    return (
        <div className="contact-container">
            <h1 className="contact-message">
                Contact
            </h1>
            <hr className="hr-contact"/>
            <div className="contact-body">
                <div className="contact-section left-content">
                <LoadScript googleMapsApiKey="AIzaSyARunaQiss8nOIBYLxtE5QD5x5Rpi3BA7o">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={15}
                    >
                    </GoogleMap>
                </LoadScript>
                </div>
                <div className="contact-section right-content">
                    <h2>Contact Information</h2>
                    <div className="contact-info">
                        <div className="contact-title">
                            Address:
                        </div> 
                        <div className='contact-text'>268 Post Rd Ste 200</div>
                        <div className='contact-text'>PMB #924939</div>
                        <div className='contact-text'>Fairfield, CT 06824-6220, USA</div>
                    </div>
                    <div className="contact-info">
                        <div className="contact-title">Email:</div>
                        <div className='contact-text'>sales@gantrymatic.com</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
